let moment = require('moment');
export default {
	data() {
		return {
			_state: {
				path: '',
				query: {},
				params: {}
			}
		}
	},
	methods: {
		runAllSync() {
			if (arguments.length < 2) {
				console.log("Minimum 2 arguments required in runAllSync"); return;
			}
			let works = [];
			let cb = arguments[arguments.length - 1];

			function tryCb(err) {
				if (err) {
					cb(err);
					return;
				}
				if (works.length) {
					works.shift()(tryCb);
				} else {
					cb();
				}
			}

			if (typeof arguments[0] == 'object') {
				for (let i in arguments[0]) {
					if (typeof arguments[0][i] == 'function') {
						works.push(arguments[0][i]);
					}
				}
			} else {
				for (let i in arguments) {
					if (arguments.length - 1 == i) break;
					if (typeof arguments[i] == 'function') {
						works.push(arguments[i]);
					}
				}
			}
			if (!works.length) {
				cb(); return;
			}

			works.shift()(tryCb);
		},
		runAll() {
			if (arguments.length < 2) {
				console.log("Minimum 2 arguments required in runAll"); return;
			}
			let pendingWorks = 0;
			let cpus = 4;
			let works = [];
			let cb = arguments[arguments.length - 1];
			let stop = false;

			function tryCb(err) {
				if (stop) return;
				if (err) {
					cb(err);
					stop = true;
					return;
				}
				pendingWorks--;
				if (works.length) {
					if (pendingWorks < cpus) {
						pendingWorks++;
						works.shift()(tryCb);
					}
				} else if (pendingWorks < 1) {
					cb();
				}
			}

			if (typeof arguments[0] == 'object') {
				for (let i in arguments[0]) {
					if (typeof arguments[0][i] == 'function') {
						works.push(arguments[0][i]);
					}
				}
			} else {
				for (let i in arguments) {
					if (arguments.length - 1 == i) break;
					if (typeof arguments[i] == 'function') {
						works.push(arguments[i]);
					}
				}
			}

			let cond = true;
			while (cond) {
				if (works.length) {
					if (pendingWorks < cpus) {
						pendingWorks++;
						works.shift()(tryCb);
					} else {
						break;
					}
				} else {
					break;
				}
			}
		},
		format(time, format) {
			return moment(time).format(format);
		},
		fromNow(time) {
			return moment(time).fromNow();
		},
		exportToCSV(filename, rows) {
			var processRow = function (row) {
				var finalVal = '';
				for (var j = 0; j < row.length; j++) {
					var innerValue = row[j] === null ? '' : row[j].toString();
					if (row[j] instanceof Date) {
						innerValue = row[j].toLocaleString();
					}
					var result = innerValue.replace(/"/g, '""');
					if (result.search(/("|,|\n)/g) >= 0)
						result = '"' + result + '"';
					if (j > 0)
						finalVal += ',';
					finalVal += result;
				}
				return finalVal + '\n';
			}

			var csvFile = '';
			for (var i = 0; i < rows.length; i++) {
				csvFile += processRow(rows[i]);
			}

			var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
			if (navigator.msSaveBlob) { // IE 10+
				navigator.msSaveBlob(blob, filename);
			} else {
				var link = document.createElement("a");
				if (link.download !== undefined) { // feature detection
					// Browsers that support HTML5 download attribute
					var url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", filename);
					link.style.visibility = 'hidden';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		}
	},
	created() {
		if (typeof this.enter != 'function' && typeof this.update != 'function' && typeof this.exit != 'function') return;
		let path = this.$route.path.split("/");
		for (let pi in this.$route.params) {
			let p = this.$route.params[pi];
			let i = path.lastIndexOf(p);
			if (i != -1) path.splice(i, 1);
		}
		path = path.join("/");

		this._state.path = path;
		this._state.query = JSON.parse(JSON.stringify(this.$route.query));
		this._state.params = JSON.parse(JSON.stringify(this.$route.params));
		if (typeof this.enter == 'function') this.enter();
		if (typeof this.update == 'function') this.update();
	},
	watch: {
		'$route'(to, from) {
			if (typeof this.enter != 'function' && typeof this.update != 'function' && typeof this.exit != 'function') return;
			let path = to.path.split("/");
			for (let pi in to.params) {
				let p = this.$route.params[pi];
				let i = path.lastIndexOf(p);
				if (i != -1) path.splice(i, 1);
			}
			path = path.join("/");

			if (path == this._state.path) {
				if (typeof this.enter == 'function') this.enter();
				let state = {
					path,
					query: JSON.parse(JSON.stringify(to.query)),
					params: JSON.parse(JSON.stringify(to.params))
				}
				if (JSON.stringify(this._state) != JSON.stringify(state)) {
					this._state.query = JSON.parse(JSON.stringify(to.query));
					this._state.params = JSON.parse(JSON.stringify(to.params));
					if (typeof this.update == 'function') this.update();
				}
			}

			path = from.path.split("/");
			for (let pi in from.params) {
				let p = this.$route.params[pi];
				let i = path.lastIndexOf(p);
				if (i != -1) path.splice(i, 1);
			}
			path = path.join("/");
			if (path == this._state.path && typeof this.exit == 'function') this.exit();
		},
	}
}