import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
// import "./assets/fw/fa6/css/all.min.css"
import "font-awesome/css/font-awesome.min.css"

import "jquery/dist/jquery.min.js"
import "bootstrap/dist/js/bootstrap.bundle.min.js"


import "primevue/resources/themes/saga-blue/theme.css"       //theme
import "primevue/resources/primevue.min.css"                 //core css
import "primeicons/primeicons.css"
import "./assets/css/style.css" 


import PrimeVue from 'primevue/config';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import AutoComplete from 'primevue/autocomplete';

import HttpMixin from './mixins/HttpMixin.js'
import FormControlMixin from './mixins/FormControlMixin.js'
import FilerMixin from './mixins/Filters.js'
// import ToastsMixin from './mixins/ToastsMixin.js'
import UtilityMixin from './mixins/UtilityMixin.js'
import ToastService from 'primevue/toastservice';
import CKEditor from '@ckeditor/ckeditor5-vue';

const app = createApp(App).use(router).use(PrimeVue);

app.use(ToastService);
app.use( CKEditor );

app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('InputText', InputText);
app.component('AutoComplete', AutoComplete);
   

app.mixin(HttpMixin);
app.mixin(FormControlMixin);
app.mixin(FilerMixin);
// app.mixin(ToastsMixin);
app.mixin(UtilityMixin);

app.mount('#app')
