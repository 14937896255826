<template>
	<div style="text-align: center; margin-top: 100px; font-size: 20px;">
		<router-link to="/admin-login">Admin Login</router-link> <br> <br>
		<router-link to="/center-login">Center Login</router-link> <br> <br>
		<router-link to="/radiologist-login">Doctor Login</router-link> <br> <br>
		<router-link to="/cardiologist-login">Cardiologist Login</router-link> <br> <br>
		<router-link to="/pulmonologist-login">Pulmonologist Login</router-link> <br> <br>
		<router-link to="/neurologist-login">Neurologist Login</router-link> <br> <br>
		<router-link to="/agent-login">Agent Login</router-link> <br> <br>
		<router-link to="/track-report">Track Report</router-link> <br> <br>
	</div>
</template>
<script>

export default {
	data(){
		return {

		}
	}
}
</script>
<style scoped>
</style>