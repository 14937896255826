let validationFunctions = {
	optional(val) {
		let hasValue = false;
		if (typeof val == 'object' && val != null) {
			let x = Object.values(val);
			hasValue = (x.length && x[0]);
		} else {
			hasValue = !!val;
		}
		if (hasValue) return false;
		return { stop: true };
	},
	required(val) {
		if (typeof val == 'object') {
			if(!val) return true;
			let x = Object.values(val);
			return !(x.length && x[0]);
		}
		return !val;
	},
	requiredIfTrue(val, check, that) {
		if (!that.fdata[check]) return { stop: true };
		if (typeof val == 'object') {
			let x = Object.values(val);
			return !(x.length && x[0]);
		}
		return !val;
	},
	requiredIfFalse(val, check, that) {
		if (that.fdata[check]) return false;
		if (typeof val == 'object') {
			let x = Object.values(val);
			return !(x.length && x[0]);
		}
		return !val;
	},
	in(val, options) {
		options = options.split(",");
		return !options.includes(val);
	},
	notgt(val, check, that) {
		if ((Number(val) <= Number(that.fdata[check]))) return false;
		return {
			check: check.split('_').join(" ")
		};
	},
	gte(val, min){
		if(Number(val) < Number(min)){
			return {min: min}
		}
		return false;
	},
	min(val, len) {
		if (typeof val != 'string'){
			return { minLength: len };
		}
		if (val.length < len) {
			return {
				minLength: len,
			}
		}
		return false;
	},
	max(val, len) {
		if (typeof val != 'string') return { maxLength: len };
		if (val.length > len) {
			return {
				maxLength: len,
			}
		}
		return false;
	},
	same(val, match, that) {
		return that.fdata[match] != val;
	},
	phone(val) {
		return !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/.test(val);
	},
	email(val) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return !re.test(String(val).toLowerCase());
	},
	musttrue(val) {
		return !val;
	},
	gst(val) {
		function checkgst(g) {
			let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g)
			if (regTest) {
				let a = 65, b = 55, c = 36;
				return Array['from'](g).reduce((i, j, k, p) => {
					p = (p = (j.charCodeAt(0) < a ? parseInt(j) : j.charCodeAt(0) - b) * (k % 2 + 1)) > c ? 1 + (p - c) : p;
					return k < 14 ? i + p : j == ((c = (c - (i % c))) < 10 ? c : String.fromCharCode(c + b));
				}, 0);
			}
			return regTest
		}
		return !checkgst(val);
	},
	decimal(val) {
		return !/^\d+(\.\d{1,2})?$/.test(val);
	}
}

export default {
	data() {
		return {
			fdatareset: null,
			fcontrol: {},
		}
	},
	watch: {
		fdata: {
			handler: function (fdata) {
				if (!this.fdata || !this.fvalidator) return;
				this.fvalidate(fdata);
			},
			deep: true
		}
	},
	mounted() {
		if (!this.fdata || !this.fvalidator) return;
		this.fdatareset = JSON.parse(JSON.stringify(this.fdata));

		for (let i in this.fvalidator) {
			this.fcontrol[i] = {
				touched: false,
				errors: {},
				hasError: false,
				validators: []
			}
			let validators = this.fvalidator[i];
			if (typeof validators == 'string' && validators.length) validators = validators.split("|");

			for (let j in validators) {
				if (typeof validators[j] == 'string') {
					if (validators[j].indexOf(":") != -1) {
						let x = validators[j].split(":");
						let name = x.shift();
						this.fcontrol[i].validators.push({
							name: name,
							params: x
						});
					} else {
						this.fcontrol[i].validators.push({
							name: validators[j]
						});
					}
				} else {
					this.fcontrol[i].validators.push({
						name: validators[j][0],
						function: validators[j][1]
					});
				}
			}
			if (!this.fcontrol[i].validators.find(v => v.name == 'required') && !this.fcontrol[i].validators.find(v => v.name == 'requiredIfTrue') && !this.fcontrol[i].validators.find(v => v.name == 'requiredIfFalse')) {
				this.fcontrol[i].validators.unshift({
					name: "optional"
				});
			}
		}
		this.fvalidate(this.fdata);
	},
	methods: {
		fvalidate(fdata) {
			for (let field in this.fcontrol) {
				this.fcontrol[field].errors = {};
				this.fcontrol[field].hasError = false;

				for (let validator of this.fcontrol[field].validators) {
					let o;
					if (validator.function) {
						o = validator.function(fdata[field], this);
					} else {
						if (validator.params) {
							o = validationFunctions[validator.name](fdata[field], ...validator.params, this);
						} else {
							o = validationFunctions[validator.name](fdata[field], this);
						}
					}
					if (o) {
						if (typeof o != "object" || !o.stop) {
							this.fcontrol[field].hasError = true;
							this.fcontrol[field].errors[validator.name] = o;
						}
						break;
					}
				}
			}
		},
		fHasError() {
			this.fvalidate(this.fdata);
			let hasError = false;
			for (let i in this.fcontrol) {
				if (this.fcontrol[i].hasError) {
					hasError = true;
					if (!this.fcontrol[i].touched) this.fcontrol[i].touched = true;
				}
			}
			return hasError;
		},
		fSetErrors(errors) {
			if (!errors) return;
			for (let i in errors) {
				this.fcontrol[i].hasError = true;
				this.fcontrol[i].touched = true;
				this.fcontrol[i].errors = errors[i];
			}
		},
		fSetTouched(arr) {
			let hasError = false;
			for (let i of arr) {
				if (this.fcontrol[i].hasError) {
					hasError = true;
					this.fcontrol[i].touched = true;
				}
			}
			return hasError;
		},
		freset() {
			if (!this.fdatareset) return;
			this.fdata = JSON.parse(JSON.stringify(this.fdatareset));
			for (let i in this.fcontrol) {
				this.fcontrol[i].touched = false;
			}
		},
		fpopulate(data) {
			for (let i in this.fdata) {
				if (i in data) {
					this.fdata[i] = data[i];
				}
			}
		}
	}
}